import Bugsnag from '@bugsnag/js'

let axios
let url

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  zonesByNrdCode (codes) {
    const requestParams = {
      nrdCode: codes,
      pagination: false
    }
    try {
      return axios.get(url + '/zones', { params: requestParams })
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  zones () {
    const requestParams = {
      services: {
        code: 'ybk'
      },
      pagination: false
    }
    try {
      return axios.get(url + '/zones', { params: requestParams })
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  units () {
    const requestParams = {
      services: {
        code: 'ybk'
      },
      pagination: false
    }
    try {
      return axios.get(url + '/units', { params: requestParams })
    } catch (e) {
      Bugsnag.notify(e)
    }
  }
}
