import qs from 'qs'
import backoffice from '../api/backoffice'
import yearbook from '../api/yearbook'
import globalServices from '../api/globalServices'

export default async function (ctx, inject) {
  if (!ctx.store) {
    console.error('Please make sure store module is added')
    return
  }
  if (!ctx.$axios) {
    console.error('Please make sure $axios module is added')
    return
  }
  if (!ctx.$config) {
    console.error('Please make sure $config module is added')
    return
  }

  backoffice.setAxios(ctx.$axios)
  backoffice.setUrl(ctx.$config.backofficeApiUrl)
  if (ctx.store.state.publicToken === null) {
    const publicToken = await backoffice.getToken(ctx.$config.publicUsername, ctx.$config.publicPassword)
    ctx.store.commit('INIT_PUBLIC_TOKEN', publicToken)
  }

  if (ctx.$cookies.get('user_token') !== undefined) {
    ctx.$axios.setToken(ctx.$cookies.get('user_token'), 'Bearer')
  } else {
    // set token for client side request
    ctx.$axios.setToken(ctx.store.state.publicToken, 'Bearer')
  }
  ctx.$axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'indices', allowDots: true })
  }
  // ctx.$axios.interceptors.request.use((request) => {
  //   console.log('Starting Request', JSON.stringify(request, null, 2))
  //   return request
  // })
  // ctx.$axios.interceptors.response.use((response) => {
  //   console.log('Response:', response.data)
  //   return response
  // })

  backoffice.setPublicToken(ctx.store.state.publicToken)
  yearbook.setAxios(ctx.$axios)
  yearbook.setUrl(ctx.$config.yearbookApiUrl)
  globalServices.setAxios(ctx.$axios)
  globalServices.setUrl(ctx.$config.globalServicesApiUrl)
  inject('yearbook', yearbook)
  inject('backoffice', backoffice)
}
