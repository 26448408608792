import qs from 'qs'
import Bugsnag from '@bugsnag/js'

let axios
let url
let publicToken

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  setPublicToken (token) {
    publicToken = token
  },
  async getToken (username, password) {
    try {
      const response = await axios.post(url + '/login_check', qs.stringify({ username, password }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return response.data.token
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getContact () {
    try {
      const response = await axios.get(url + '/get_contact')
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async sendForgotPassword (mail, service) {
    try {
      await axios.post(url + '/global/send_forgot_password_form', qs.stringify({ mail, service }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async updatePassword (id, token, newPassword) {
    try {
      await axios.post(url + '/internal/update_password_for_id_with_token', qs.stringify({ id, token, newPassword }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getListSectors (language) {
    if (language === 'en') {
      language = 'uk'
    } else if (language === 'jp') {
      language = 'ja'
    }
    try {
      const response = await axios.get(url + '/internal/get_list_sector/' + language)
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getListCountries (language) {
    if (language === 'en') {
      language = 'uk'
    } else if (language === 'jp') {
      language = 'ja'
    }
    try {
      const response = await axios.get(url + '/internal/get_list_country/' + language)
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getListOptions (selectName, language) {
    if (language === 'en') {
      language = 'uk'
    }
    try {
      const response = await axios.get(url + '/internal/get_list_select/' + selectName + '/' + language)
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getCaptcha (formId) {
    try {
      const response = await axios.get(url + '/internal/get_captcha/' + formId)
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async sendRegister (form) {
    try {
      await axios.post(url + '/global/send_register_form', qs.stringify(form), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async activateUser (mail) {
    try {
      const response = await axios.get(url + '/internal/activate_user_by_mail/Yearbook/' + mail)
      return response.data.token
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getActiveDemos () {
    try {
      const response = await axios.get(url + '/get_active_demos')
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async savePublication (title) {
    try {
      await axios.post(url + '/save_publication', qs.stringify({ title }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getActiveSubscriptions () {
    try {
      const response = await axios.get(url + '/get_active_subscriptions')
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async createDemo (serviceId, origin) {
    try {
      const response = await axios.post(url + '/add_demo_with_notification', qs.stringify({
        service: serviceId,
        origin
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async getInteretsForService () {
    const config = {
      headers: {
        Authorization: `Bearer ${publicToken}`
      }
    }
    try {
      const response = await axios.get(url + '/internal/get_interest_service/8', config)
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
    }
  },
  async addInterest (interestId) {
    await axios.post(url + '/user/interests/' + interestId, [], {
      validateStatus (status) {
        return status < 500 // avoid error on 403 when interest already exists
      }
    })
  }
}
