import Bugsnag from '@bugsnag/js'
import DataVO from '../models/DataVO'

let axios
let url

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  async values (requestParams = {}, vueInstance) {
    requestParams.locale = vueInstance.$i18n.locale
    try {
      const response = await axios.get(url + '/values', { params: requestParams })
      const i18n = vueInstance.$i18n
      const zones = vueInstance.$store.getters.zones
      const units = vueInstance.$store.getters.units
      return response.data.map(item => new DataVO(item, zones, units, i18n))
    } catch (e) {
      Bugsnag.notify(e)
      return []
    }
  },
  async getXlsxFile (filename) {
    try {
      return await axios.get(url + '/secured/files/xlsx/' + filename, {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob'
      })
    } catch (e) {
      Bugsnag.notify(e)
    }
  }
}
